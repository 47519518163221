/* .name_phone::last-word {
    color: red;
} */

/* .name_phone {
    color: red;
} */

/* thm1 NIGHT */
.self_imageV_thm1::before {
    content: "";
    background: aquamarine;
    position: absolute;
    top: -3vh;
    left: 3.5vw;
    height: 2.5vw;
    width: 0.33vw;
}

.self_imageH_thm1::before {
    content: "";
    background: aquamarine;
    position: absolute;
    top: -3vh;
    left: 3.5vw;
    width: 6vw;
    height: 0.15vw;
}

.self_imageV_thm1::after {
    content: "";
    background: aquamarine;
    position: absolute;
    bottom: -5vh;
    right: 3.5vw;
    height: 2.5vw;
    width: 0.33vw;
}

.self_imageH_thm1::after {
    content: "";
    background: aquamarine;
    position: absolute;
    bottom: -5vh;
    right: 3.5vw;
    width: 6vw;
    height: 0.15vw;
}



/* thm1 DAY */
.self_imageV_thm1_day::before {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    top: -3vh;
    left: 3.5vw;
    height: 2.5vw;
    width: 0.33vw;
}

.self_imageH_thm1_day::before {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    top: -3vh;
    left: 3.5vw;
    width: 6vw;
    height: 0.15vw;
}

.self_imageV_thm1_day::after {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    bottom: -5vh;
    right: 3.5vw;
    height: 2.5vw;
    width: 0.33vw;
}

.self_imageH_thm1_day::after {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    bottom: -5vh;
    right: 3.5vw;
    width: 6vw;
    height: 0.15vw;
}





/* ============================================================  For Moblie */



/* thm1 DAY */
.self_imageV_p_thm1_day::before {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    top: -1.5vh;
    left: 6vw;
    height: 4.5vw;
    width: 0.7vw;
}

.self_imageH_p_thm1_day::before {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    top: -1.5vh;
    left: 6vw;
    width: 17vw;
    height: 0.29vw;
}

.self_imageV_p_thm1_day::after {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    bottom: -4vh;
    right: 3.23vw;
    height: 4.5vw;
    width: 0.7vw;
}

.self_imageH_p_thm1_day::after {
    content: "";
    background: rgb(6, 110, 105);
    position: absolute;
    bottom: -4vh;
    right: 3.23vw;
    width: 17vw;
    height: 0.29vw;
}
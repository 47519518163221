.inputs_container input::placeholder {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border-color: inherit;

}

.footer_main_p {
    transition: background-color 1000ms linear;
}

/* .contact_elm {
    font-size: 2.5vh;
    transition: font-size 0.5s;
    transition-delay: 0.1s;
}

.contact_elm:hover {
    font-size: 3vh;
} */
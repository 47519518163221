body {
  /* transition: background-color 0.9s; */
  /* background-color: rgb(87, 87, 87); */
}
.shadow_seperator{
    box-shadow: 0 10px 100px 0 #555;
  /*-moz-box-shadow: inset 0 -60px 60px -60px #555;
  -webkit-box-shadow: inset 0 -60px 60px -60px #555;
  box-shadow: inset 0 -60px 60px -60px #555;*/

  /*-webkit-box-shadow:  0 80px 60px -60px #555;
  -moz-box-shadow:  0 80px 60px -60px #555;
  box-shadow:  0 80px 60px -60px #555;*/
  /*box-shadow: inset 0px 0px 10px 20px #555;*/
}
.shadow_seperator_bottom_only{
  -webkit-box-shadow:  0 20px 60px -30px #555;
  -moz-box-shadow:  0 20px 30px -30px #555;
  box-shadow:  0 20px 30px -30px #555;
}
.shadow_seperator_top_only{
  -webkit-box-shadow: 0px -80px 60px #555;
  -moz-box-shadow: 0px -80px 60px #555;
  box-shadow: 0px -80px 60px #555;
}

.container {
  height: 100vh;
  overflow-x: auto;
  /* transition: background-color 1200ms; */
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}


.App::-webkit-scrollbar {
  display: none;
}

.App {
  /* height: 100vh; */
  overflow-y: auto;
  text-align: center;
  display: flex;
  min-width: 250px;
}

.App_top {
  /* height: 100vh; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

.content {
  min-height: 110vh;
  width: 90vw;
  display: flex;
  justify-content: space-between;
}


.left_c {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right_c {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.App-logo {
  max-width: 100%;
}

.typed {
  overflow: hidden;
  font-size: 3vw;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0;
  animation: typing 1.5s steps(30, end) forwards, blinking 1s 2s;
}

/* --------------------------------------content -CSSTRANSITIONS -start*/

.content-enter {
  transform: translateY(-5vh);
}

.content-enter-active {
  transform: translateY(0);
  transition: transform 300ms;
}

.content-exit {
  transform: translateY(0%);
}

.content-exit-active {
  transform: translateY(-5vh);
  transition: transform 300ms;
}



/* --------------------------------------content-CSSTRANSITIONS -ends*/

/* --------------------------------------setting_modal -CSSTRANSITIONS -start*/

.setting_modal-enter {
  opacity: 0;
  transform: translateX(10vh);
}

.setting_modal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 500ms, opacity 500ms;
}

.setting_modal-exit {
  opacity: 1;
  transform: translateX(0);
}

.setting_modal-exit-active {
  transform: translateX(10vh);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
}

/* --------------------------------------setting_modal-CSSTRANSITIONS -ends*/

/* --------------------------------------setting_modal_p -CSSTRANSITIONS -start*/

.setting_modal_p-enter {
  opacity: 0;
  transform: translateX(-10vh);
}

.setting_modal_p-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 500ms, opacity 500ms;
}

.setting_modal_p-exit {
  opacity: 1;
  transform: translateX(0);
}

.setting_modal_p-exit-active {
  transform: translateX(-10vh);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
}

/* --------------------------------------setting_modal-CSSTRANSITIONS -ends*/



/* @keyframes typing {
  from {
    width: 0
  }

  to {
    width: 45%
  }
} */
/* 
@keyframes typingg {

  from {
    width: 0
  }

  to {
    width: 80%
  }
} */


@keyframes blinking {
  0% {
    border-right-color: transparent
  }

  50% {
    /* border-right-color: #61dafb */
    /* border-right-color: black; */
  }

  100% {
    border-right-color: transparent
  }
}